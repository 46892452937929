.audio-recorder {
    box-shadow: none !important;
    background: #90caf9;
    color: #fff !important;
    width: 39px;
}
.audio-recorder.recording {
    width: 300px;
}

.audio-recorder-mic {
   padding: 12px;
}

audio {
    background-color: #1E1E1E;
    box-shadow: none;
    width: 100%;
}

audio::-webkit-media-controls-panel,
audio::-webkit-media-controls,
audio::-webkit-media-controls-play-button {
    background-color: #fff;
    color: #fff;
}