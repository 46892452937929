/* html {
  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
} */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 4px;
}

.rpv-toolbar__right {
  display: none !important;
}

.rhap_container{
  box-shadow: none !important;
}

.audioPlayer{
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.audioPlayer .rhap_container{
  padding: 0;
}